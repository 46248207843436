import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import { AnimatePresence, motion } from "framer-motion"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ProjectCard from "../components/project-card"

export const projectListQuery = graphql`
  query projectListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "project" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`
const Pagination = props => (
  <div className="pagination">
    <ul>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left">
              <RiArrowLeftLine />
            </span>{" "}
            Previous
          </Link>
        </li>
      )}
      {Array.from({ length: props.numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`}>
          <Link
            to={`${props.blogSlug}${i === 0 ? "" : i + 1}`}
            className={props.currentPage === i + 1 ? "is-active num" : "num"}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            Next{" "}
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const blogVariants = {
  enter: { transition: { staggerChildren: 0.1 } },
  exit: { transition: { staggerChildren: 0.1 } },
}

const ProjectIndex = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const blogSlug = "/projects/"
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? blogSlug : blogSlug + (currentPage - 1).toString()
  const nextPage = blogSlug + (currentPage + 1).toString()

  const posts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <ProjectCard key={edge.node.id} data={edge.node} />)

  let props = {
    isFirst,
    prevPage,
    numPages,
    blogSlug,
    currentPage,
    isLast,
    nextPage,
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <Layout className="blog-page">
        <SEO
          title={"Projects — Page " + currentPage + " of " + numPages}
          description={
            "Kikxle base projects page " + currentPage + " of " + numPages
          }
        />
        <motion.div
          key="project-list"
          className="wrapper"
          initial="initial"
          animate="enter"
          exit="exit"
          variants={blogVariants}
        >
          <h1 className="page-title">Projects</h1>
          <hr />
          <div className="grids col-1 sm-2 md-2">{posts}</div>
        </motion.div>
        <Pagination {...props} />
      </Layout>
    </AnimatePresence>
  )
}

export default ProjectIndex
